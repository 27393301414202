<template>
	<div class="register_page">
		<loginRegistration pageType="register">
			<div class="flex flex_box">
				<div class="slogan_tit_1">免费注册</div>
				<div class="slogan_tit_3">注册就送15元店铺备用金</div>
			</div>
			<div class="register_form">
				<a-input
					placeholder="请输入11位手机号"
					v-model="phone"
					type="tel" />
				<div class="flex margin_b_44">
					<a-input
						placeholder="验证码"
						v-model="verify"
						type="number" />
					<div class="button" @click="getCode" v-if="!isGetCode">
						{{ btnText }}
					</div>
					<div class="button_disabled" v-else>{{ downTime }}s</div>
				</div>
				<a-input-password
					placeholder="请输入密码（6位数字）"
					:max-length="6"
					v-model="password" />
				<div class="submit_btn" @click="goRegister">确认注册</div>
			</div>
			<a-checkbox v-model="agreementChecked">
				我已阅读并同意
				<a
					href="https://www.dinggebaojian.com/agreement/fwxy.pdf"
					target="_blank">
					《订个包间服务协议》
				</a>
				<a
					href="https://www.dinggebaojian.com/agreement/bmxy.pdf"
					target="_blank">
					《订个包间保密协议》
				</a>
			</a-checkbox>
			<div class="tips_box">
				<span class="tips_1">已有账号?</span>
				<span class="tips_2" @click="goLogin">去登录</span>
			</div>
		</loginRegistration>
	</div>
</template>
<script>
import loginRegistration from '../../components/loginRegistration'
import { getVerifyCode, register } from '@/api/Official'

export default {
	components: {
		loginRegistration
	},
	data() {
		return {
			phone: '',
			password: '',
			verify: '',
			downTime: 60,
			isGetCode: false, // 是否已经获取了验证码
			btnText: '获取验证码',
			agreementChecked: false
		}
	},
	methods: {
		goLogin() {
			this.$router.push({ name: 'OfficialLogin' })
		},
		getCode() {
			if (this.isGetCode) {
				return
			}
			const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
			if (!this.phone) {
				this.$message.warning('请输入11位手机号！')
				return
			} else if (!phoneReg.test(this.phone)) {
				this.$message.warning('请输入正确的手机号！')
				return
			}
			this.getCodeAsync(this.phone)
		},
		getCodeAsync(phone = '') {
			getVerifyCode({ phone, type: 1 }).then(res => {
				if (res.code === 0) {
					this.isGetCode = true
					let downTime = 60
					const intervalID = setInterval(() => {
						downTime--
						if (downTime <= 0) {
							clearInterval(intervalID)
							this.isGetCode = false
							this.btnText = '重新获取'
						}
						this.downTime = downTime
					}, 1000)
				}
			})
		},
		goRegister() {
			const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
			if (!this.agreementChecked) {
				this.$message.warning('请同意服务协议和保密协议')
			} else if (!this.phone) {
				this.$message.warning('请输入11位手机号！')
			} else if (!phoneReg.test(this.phone)) {
				this.$message.warning('请输入正确的手机号！')
			} else if (!this.verify || this.verify.length !== 4) {
				this.$message.warning('请输入4位验证码！')
			} else if (!this.password) {
				this.$message.warning('请输入密码！')
			} else {
				const params = {
					phone: this.phone.trim(),
					password: this.password.trim(),
					verify: this.verify.trim()
				}
				register(params).then(res => {
					if (res.code === 0) {
						this.$message
							.success('注册成功')
							.then(this.$router.push({ name: 'OfficialLogin' }))
					}
				})
			}
		}
	}
}
</script>
<style lang="less">
.register_form {
	.ant-input {
		height: 44px !important;
		margin-bottom: 42px;
	}

	.ant-input-affix-wrapper {
		height: 44px !important;
	}
}

.ant-message-warning .anticon {
	color: #e43937;
}
</style>
<style scoped lang="less">
.flex_box {
	display: flex;
	align-items: center;
	.slogan_tit_1 {
		font-weight: 500;
		font-size: 30px;
		color: #333333;
		text-align: center;
	}
	.slogan_tit_3 {
		font-weight: 500;
		font-size: 20px;
		color: #e43937;
		margin-left: 38px;
		flex-shrink: 0;
		white-space: nowrap;
	}
}

.register_page {
	width: 100%;
	height: 100%;
	background: #f5f5f5;
}

.register_form {
	margin-top: 40px;

	.margin_b_44 {
		margin-bottom: 42px;
	}

	.flex {
		.ant-input {
			width: 250px;
			margin-bottom: 0;
		}

		.button {
			width: 190px;
			height: 44px;
			background: #ffffff;
			border: 1px solid #e43937;
			border-radius: 2px;
			font-weight: 500;
			font-size: 14px;
			color: #e43937;
			text-align: center;
			line-height: 44px;
			margin-left: 9px;
			cursor: pointer;
		}

		.button_disabled {
			width: 190px;
			height: 44px;
			background: #ffffff;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 2px;
			font-weight: 500;
			font-size: 14px;
			color: #999999;
			text-align: center;
			line-height: 44px;
			margin-left: 9px;
		}
	}

	.submit_btn {
		width: 100%;
		height: 52px;
		background: #e43937;
		border-radius: 2px;
		font-weight: 500;
		font-size: 18px;
		color: #ffffff;
		text-align: center;
		line-height: 52px;
		margin-top: 42px;
		cursor: pointer;
	}
}

.tips_box {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 13px;

	.tips_1 {
		color: #666666;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
	}

	.tips_2 {
		color: #e43937;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		margin-left: 10px;
		cursor: pointer;
	}
}

::v-deep .ant-checkbox-wrapper {
	margin-top: 21px;
	font-size: 14px;
	font-weight: 500;
}
</style>
